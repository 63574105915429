import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography,} from '@mui/material';

const PasswordResetSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate('/password-reset-success', { replace: true });
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

   

    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                textAlign="center"
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Password Reset Successful
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your password has been reset successfully. You can close this window.
                </Typography>

            </Box>
        </Container>
    );
};

export default PasswordResetSuccess;