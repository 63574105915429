import React, { useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
//import base64url from 'base64-url';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff, CheckCircleOutline } from '@mui/icons-material';

const PasswordResetForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();
    const [mToken, setToken] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        if (token) {
          try {

            setToken(token);
          
          } catch (error) {
            console.error('Failed to decode token:', error);
          }
        }
      }, [token]);


    

    const handleSubmit = async (e) => {
   
        e.preventDefault();
        
        // Password validation
        if (!isPasswordValid(password)) {
            setError('Password must be at least 8 characters long and alphanumeric.');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await axios.post(`${baseURL}/auth/update-password/`, 
            {
                new_password: password,
                token: mToken
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            );

            // Show success dialog
            setSuccessDialogOpen(true);
        } catch (err) {
            
            setError('Error resetting password');
            setSuccessDialogOpen(true);

        }
    };

    const isPasswordValid = (password) => {
        // Password must be at least 8 characters long and alphanumeric
        const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
        return regex.test(password);
    };

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        navigate('/password-reset-success', { replace: true }); // Redirect to home page
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Reset Password
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Fill the form below to reset your password.
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {error && <Typography variant="body2" color="error" gutterBottom>{error}</Typography>}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '16px', backgroundColor: '#1976d2', color: '#ffffff' }}
                    >
                        Reset Password
                    </Button>
                </form>
            </Box>

            {/* Success Dialog */}
            <Dialog open={successDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Password Reset Successful</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                        <CheckCircleOutline sx={{ color: 'green', fontSize: 48, marginRight: 2 }} />
                        <Typography variant="body1">Your password has been successfully reset.</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PasswordResetForm;
