// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Removed 'Link' import
import PasswordResetForm from './pages/PasswordResetForm';
import PasswordResetSuccess from './pages/PasswordResetSuccess';
import LandingPage from './pages/LandingPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/password-reset-confirm/:token" element={<PasswordResetForm />} />
                <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
                {/* Define other routes here if needed */}
            </Routes>
        </Router>
    );
}

export default App;
